/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'fHwJohcd1d8KuXwaLcVYVK.webp': { uri: '/images/fHwJohcd1d8KuXwaLcVYVK.webp' },
'6tVvSDvcwCTpJE2yZYz6KY.png': { uri: '/images/6tVvSDvcwCTpJE2yZYz6KY.png' },
'n3cQUb3vBQ15q7T4784R64.png': { uri: '/images/n3cQUb3vBQ15q7T4784R64.png' },
'ewJgdFZDnddp1rNBJ4mfFN.png': { uri: '/images/ewJgdFZDnddp1rNBJ4mfFN.png' },
'pfAjJ1HiEJRDE1zRgp1W7M.png': { uri: '/images/pfAjJ1HiEJRDE1zRgp1W7M.png' },
'7WM2Wq9L5cqXDhxFvkcEWv.png': { uri: '/images/7WM2Wq9L5cqXDhxFvkcEWv.png' },
'7DBbqzhWjq5WRVCSWBRcFv.png': { uri: '/images/7DBbqzhWjq5WRVCSWBRcFv.png' },
'r8vM499VC1nyXsxqqx1jU2.png': { uri: '/images/r8vM499VC1nyXsxqqx1jU2.png' },
'3Qf9ZfM8JnQj1uP75jDTCm.png': { uri: '/images/3Qf9ZfM8JnQj1uP75jDTCm.png' },
'bupwcgf2MYGG5pGEP3PUvW.png': { uri: '/images/bupwcgf2MYGG5pGEP3PUvW.png' },
'eTnFkx3JspT97LqmvQoDQa.png': { uri: '/images/eTnFkx3JspT97LqmvQoDQa.png' },
'32jbFUPmVuefz6YTzQQTVw.jpg': { uri: '/images/32jbFUPmVuefz6YTzQQTVw.jpg' },
'nma7jQKJjKUJ9g3NZVaw1G.jpg': { uri: '/images/nma7jQKJjKUJ9g3NZVaw1G.jpg' }
}

export default imageStaticSourcesByFileName
